import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'
import PhotoGallery from 'components/PhotoGallery.js'
import TitleBar from 'components/TitleBar.js'
import Layout from "components/layout"

export default class App extends Component {
  render(){

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return(
      <Layout pathname={this.props.location.pathname}>

          <div >
            <Helmet
              title={lc[lang].pagename}
              meta={[
                { name: 'description', content: 'Sample' },
                { name: 'keywords', content: 'sample, something' },
              ]}
            />

              <PhotoGallery />
              <div style={{height: 30}} />
              <TitleBar title={lc[lang].companyname} />

          </div>
</Layout>
    )
  }
}


const lc = {
  en:{
    pagename: 'Kun Shung Holdings Limited',
    companyname: 'Kun Shung Holdings Limited',
  },
  zh:{
    pagename: '冠信控股有限公司',
    companyname: '冠信控股有限公司',
  }
}
